import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AxiosEvents from '@/services/AxiosEvents.js'
import 'nprogress/nprogress.css'

new Vue({
  router,
  store,
  created() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$store.commit('SET_TOKEN', token)
    }

    AxiosEvents.api5F.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )

    AxiosEvents.apiB1.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  vuetify,
  render: h => h(App)
}).$mount('#app')
