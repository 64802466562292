import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    groupId: '',
    token: '',
  },
  mutations: {
    SET_CURRENT_GROUP_ID(state, groupId) {
      state.groupId = groupId
    },
    SET_TOKEN(state, token) {
      localStorage.setItem('token', token)
      state.token = token
    },
    CLEAR_TOKEN(state) {
      localStorage.removeItem('token')
      state.token = ''
      location.reload()
    },
    SAVE_EVENT(state, event) {
      localStorage.setItem(
        event.reservedID,
        JSON.stringify({
          models: event.models,
          custom: event.custom,
          usage: event.usage,
        })
      )
    },
  },
  actions: {
    login({ commit }, loginData) {
      commit('SET_TOKEN', loginData.token)
      commit('SET_CURRENT_GROUP_ID', loginData.groupId)
    },
    logout({ commit }) {
      commit('CLEAR_TOKEN')
    },
    save({ commit }, event) {
      commit('SAVE_EVENT', event)
    },
  },
  modules: {},
})
