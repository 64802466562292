import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/accessctl/:floor',
    name: 'accessctl',
    component: () => import('@/views/Temp.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/with-login-public/:floor/:reservedID',
    name: 'with-login-public',
    component: () => import('@/views/Reservation.vue'),
    props: true,
    beforeEnter() {
      //beforeEnter(to, from, next) {
      window.location =
        'https://tact-client-new-3dakogmbua-an.a.run.app/with-login-public/NLS/new'
      // to.params.authDialog = true
      // next()
    },
  },
  {
    path: '/',
    component: () => import('@/views/PublicTopPage.vue'),
  },
  {
    path: '/temp',
    component: () => import('@/views/Temp.vue'),
  },
  {
    path: '/top',
    name: 'top',
    component: () => import('@/views/EventTop.vue'),
    meta: { requiresAuth: true },
    beforeEnter() {
      window.location = 'https://tact-client-new-3dakogmbua-an.a.run.app/login'
    },
  },

  {
    path: '/events/:floor',
    name: 'event-list',
    component: () => import('@/views/EventList.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter() {
      window.location = 'https://tact-client-new-3dakogmbua-an.a.run.app/login'
    },
  },
  {
    path: '/reservation/:floor/:reservedID',
    name: 'reserved',
    component: () => import('@/views/Reservation.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/public/:floor/:reservedID',
    name: 'public',
    component: () => import('@/views/Reservation.vue'),
    props: true,
    beforeEnter() {
      window.location =
        'https://tact-client-new-3dakogmbua-an.a.run.app/with-login-public/NLS/new'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    beforeEnter() {
      window.location = 'https://tact-client-new-3dakogmbua-an.a.run.app/login'
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    localStorage.setItem('groupId', to.params.reservedID)
    next('/login')
  }
  next()
})

export default router
