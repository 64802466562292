import axios from 'axios'
import NProgress from 'nprogress'

const baseURL5F = process.env.VUE_APP_URL_5F
const baseURL2F = process.env.VUE_APP_URL_2F
const baseURLB1 = process.env.VUE_APP_URL_B1
const baseURLNLS = process.env.VUE_APP_URL_NLS
const baseURLSLS = process.env.VUE_APP_URL_SLS

console.log(baseURL5F)
console.log(baseURLB1)
console.log(baseURL2F)
console.log(baseURLNLS)
console.log(baseURLSLS)

const api2F = axios.create({
  baseURL: baseURL2F,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const api5F = axios.create({
  baseURL: baseURL5F,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const apiB1 = axios.create({
  baseURL: baseURLB1,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const apiNLS = axios.create({
  baseURL: baseURLNLS,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const apiSLS = axios.create({
  baseURL: baseURLSLS,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
api5F.interceptors.request.use((config) => {
  NProgress.start()
  return config
})

api5F.interceptors.response.use((response) => {
  NProgress.done()
  return response
})

apiB1.interceptors.request.use((config) => {
  NProgress.start()
  return config
})

apiB1.interceptors.response.use((response) => {
  NProgress.done()
  return response
})

function comvertFormDataToObject(floor, usage, models, customModels, ccMails) {
  console.log(customModels)
  var data = {
    floor: {
      name: floor,
      rooms: customModels.roomTypes,
      layout: customModels.tableLayout,
      other: customModels.otherLayoutDetail,
    },
    usage: usage,
    summary: models.event,
    user: {
      name: models.name,
      division: models.division,
      department: models.department,
      superior: models.superior,
      email: models.email,
      phone: models.phone,
    },
    costCode: models.costCode,
    event: {
      date: models.desiredDate,
      start: models.startTime,
      duration: models.duration,
      buffer: {
        start: models.startBuffer,
        end: models.endBuffer,
      },
    },
    participants: {
      other: Number(customModels.numbers.other),
      internal: Number(customModels.numbers.inHouse),
      external: Number(customModels.numbers.outHouse),
      inHouse: Number(customModels.B1.number.inHouse),
      agency: Number(customModels.B1.number.agency),
      doctor: Number(customModels.B1.number.doctor),
      coMed: Number(customModels.B1.number.coMed),
    },
    other: models.other,
    reason: models.creason,
    meta: {
      medicalDepartment: customModels.medicalDepartment,
      otherMedicalDepartment: customModels.otherMedicalDepartment,
      bw: {
        kj: customModels.kjSettings.kj,
        merge: customModels.kjSettings.merge,
        heartModel: customModels.kjSettings.heartModel,
        catAndCurve: customModels.kjSettings.catAndCurve,
      },
      ccmails: ccMails,
      filebase64: customModels.filebase64,
      filename: customModels.filename,
      divisionDetail: customModels.divisionDetail,
    },
  }
  return data
}

function apiXF(floor) {
  switch (floor) {
    case '5F':
      return api5F
    case 'B1':
      return apiB1
    case '2F':
      return api2F
    case 'NLS':
      return apiNLS
    case 'SLS':
      return apiSLS
    default:
      throw `Invalid floor ${floor}`
  }
}

export default {
  getEventData(token, floor, id) {
    const api = apiXF(floor)
    return api.get(`/floors/${floor}/events/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  getPin(token, floor) {
    const api = apiXF(floor)
    return api.get(`/floors/${floor}/pin`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  postPin(token, floor, pin) {
    const api = apiXF(floor)
    return api.post(
      `/floors/${floor}/pin`,
      { pin: pin },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
  },

  postEventData(token, floor, usage, models, customModels) {
    var data = comvertFormDataToObject(floor, usage, models, customModels)
    const api = apiXF(floor)
    return api.post(`/public/${floor}/events/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },

  putEventData(token, floor, gid, usage, models, customModels, ccMails) {
    var data = comvertFormDataToObject(
      floor,
      usage,
      models,
      customModels,
      ccMails
    )
    const api = apiXF(floor)
    return api.put(`/floors/${floor}/events/${gid}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  getFreeSchedule(token, floor, date, duration, data, gid) {
    // date は yyyy-mm-dd の形で入力される
    const api = apiXF(floor)
    return api.post(
      `/public/${floor}/free-schedule/${date}`,
      {
        duration: duration,
        groupId: gid,
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
  },
  deleteEventData(token, floor, gid, reason) {
    const api = apiXF(floor)
    if (reason == null) {
      return api.delete(`/floors/${floor}/events/${gid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    } else {
      return api.delete(`/floors/${floor}/events/${gid}`, {
        data: { reason: reason },
        headers: { Authorization: `Bearer ${token}` },
      })
    }
  },
  postLoinUserData(userdata) {
    const api = apiXF('5F')
    return api.post('/login', userdata)
  },
  getTops(token, floor) {
    const api = apiXF(floor)
    return api.get(`/floors/${floor}/tops/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  api5F,
  apiB1,
}
